@font-face {
  font-family: Aeonik;
  src: url(../fonts/Aeonik-Light.woff2), url(../fonts/Aeonik-Light.woff);
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: Aeonik;
  src: url(../fonts/Aeonik-Light.woff2), url(../fonts/Aeonik-Light.woff);
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: Aeonik;
  src: url(../fonts/Aeonik-Regular.woff2), url(../fonts/Aeonik-Regular.woff);
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: Aeonik;
  src: url(../fonts/Aeonik-Bold.woff2), url(../fonts/Aeonik-Bold.woff);
  font-style: normal;
  font-weight: 700;
}
