.btn {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.875rem;
  border-radius: 100px;

  &.btn-primary {
    padding: 0.5rem 2rem;

    &:hover {
      &:not([disabled]) {
        background-color: darken($primary, 10%);
      }

      &[disabled] {
        cursor: not-allowed;
      }
    }
  }

  &.btn-secondary {
    padding: 0.5rem 2rem;

    &:hover {
      background-color: darken($secondary, 10%);
    }
  }

  &.btn-action {
    background-color: $custom-light-grey;
    height: 32px;
    width: 32px;
    padding: 0;
    color: $primary;

    span {
      font-size: 1.1rem;
    }

    &:hover {
      background-color: darken($custom-light-grey, 10%);
    }

    &.dropdown-toggle::after {
      display: none;
    }
  }

  &.btn-link {
    pointer-events: all;
    text-decoration: none;
    padding: 0.5rem 1rem;

    &:hover {
      &:not([disabled]) {
        background-color: darken($white, 10%);
      }

      &[disabled] {
        cursor: not-allowed;
      }
    }

    &:focus {
      text-decoration: none;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $headings-font-family;
}

h1 {
  font-size: 1.625rem;
  color: $primary;
  font-weight: normal;
}

h2 {
  color: $black;
  font-size: 1.125rem;
  font-weight: bold;
}

h3 {
  font-weight: $font-weight-bold;
  font-size: 1rem;
  color: $info;
  margin-top: 1rem;
}

p a {
  color: darken($primary, 10%);
  &:hover,
  &:focus {
    text-decoration: underline;
    color: darken($primary, 15%);
  }
}

td,
th {
  font-family: $body-font-family;
}

.form-label {
  font-family: $font-family-sans-serif;
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
}

.form-control {
  background-color: $gray-200;
  border-radius: 0;
  border: none;

  &:focus {
    background-color: $white;
    border: none;
    color: $primary;
  }
}

.modal-content {
  border-radius: 0;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
}

.nav-menu {
  background-color: $red;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
}

.badge {
  text-transform: uppercase;

  &.badge-success {
    color: $custom-green;
    background-color: $custom-light-green;
    font-size: 0.75rem;
  }

  &.badge-warning {
    color: $custom-orange;
    background-color: $custom-light-orange;
    font-size: 0.75rem;
  }

  &.badge-info {
    color: $custom-blue;
    background-color: $custom-light-blue;
    font-size: 0.85rem;
  }
}

// table stylings

.react-bootstrap-table {
  th,
  td {
    vertical-align: middle;
  }
  .link-indicator {
    color: $primary;
    font-weight: bold;
    margin-right: 5px;
  }
}

.table-hover tbody tr:hover {
  background-color: #eff4fa;
}

// fixes sort carets on table for the npm package react-bootstrap-table-next
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  color: #ccc;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  content: “”;
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid\9;
}

.react-bootstrap-table-sort-order {
  margin-left: 5px;
  .caret {
    color: $primary;
  }
}

// breadcrumbs

.breadcrumb {
  margin-left: 10px;
  font-family: $headings-font-family;
}

.breadcrumb-item {
  font-weight: bold;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "\203A";
}

// search wrap

.search-wrap {
  display: inline;
  position: relative;
  input.form-control {
    padding-left: 35px;
  }
  &:after {
    content: "";
    position: absolute;
    left: 4px;
    top: 4px;
    height: 30px;
    width: 30px;
    background: url("../img/search.svg") center center no-repeat;
    pointer-events: none;
  }
}
