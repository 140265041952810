// Bootstrap overrides
$primary: #2a6ebb;
$secondary: #e9ecef;
$info: #7fa8d6;
$red: #d14f4f;

// Font families
$headings-font-family: Aeonik, Arial;
$body-font-family: Arial;
$body-color: #4d4d4d;

// Links
$link-hover-decoration: none;
$emphasized-link-hover-darken-percentage: 10%;

// Custom colours
$custom-green: #62ae6f;
$custom-light-green: #cbf1d8;
$custom-orange: #ecb141;
$custom-light-orange: #fbeed8;
$custom-blue: #2a6ebb;
$custom-light-blue: #d4e2f0;
$custom-light-grey: #ebecec;

// Breadcrumb
$breadcrumb-bg: #fff;
$breadcrumb-divider: quote(">");
$breadcrumb-margin-bottom: 0;
