@import "bootstrap/scss/bootstrap.scss";

.mainContent {
  margin-top: -120px;

  .centreTitleContainer {
    background: $white;
    box-shadow: $box-shadow;
    border-left: 6px solid $red;
  }

  .contentContainer {
    background: $white;
    box-shadow: $box-shadow;
  }
}

.imageContainer {
  height: 195px;
  width: 100%;
  background-color: #f5f5f5;
  background-position: center center;
  background-size: cover;
}

.logo {
  width: 150px;
}
