.comment-icon {
  opacity: 0.7;
  &:hover {
    cursor: pointer;
    opacity: 1;
  }

  &.empty {
    filter: grayscale(100%);
  }
}
