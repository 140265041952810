@import "bootstrap/scss/bootstrap.scss";

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0%;
  background-color: $white;
  padding: 80px 50px 50px;
  height: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.overlay-screen {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 900;
}

.nav-close {
  background: none;
  border: 0;
  position: absolute;
  top: 18px;
  left: 18px;
}
