.menu-open {
  background: none;
  border: 0;
}

.sidebar-link {
  padding: 0;
  margin-bottom: 1;
  font-size: 1.625rem;
}

.hamburger-img {
  width: 45px;
  height: 35px;
  padding: 0px 5px;
}
