@import "scss/overrides.scss";

.comment-icon {
  opacity: 0.7;
  &:hover {
    cursor: pointer;
    opacity: 1;
  }

  &.empty {
    filter: grayscale(100%);
  }
}

.row-sub-component {
  background-color: $custom-light-blue;

  .header {
    color: $body-color;
  }
}

.comment-icon-inactive {
  opacity: 0.7;
  &.empty {
    filter: grayscale(100%);
  }
}
